<template>
  <main class="main">
    <slot />
    <!-- <Footer />
    <div class="mx-auto mt-5 text-center text-sm opacity-25">
      [Home Layout]
    </div> -->
  </main>
</template>

<style lang="less" scoped>
.main {
  --at-apply: h-full;
  background: linear-gradient(2deg, #F2F3F7 0%, #F7F8FA 100%);
}
</style>
